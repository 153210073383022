.notification-container {
  position: fixed;
  top: 100px;
  right: 10px;
  z-index: 1050;
  max-width: 400px;
}

.notification {
  position: relative;
  background-color: rgba(44, 62, 80, 0.8);
  color: #ecf0f1;
  border: 1px solid rgba(52, 73, 94, 0.6);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
}

.notification .btn-close {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  font-size: 16px;
  color: #ecf0f1;
  cursor: pointer;
}

.notification .btn-close:hover {
  color: #FFFFFF;
}

input.custom-checkbox:checked {
  accent-color: #1E466A;
}

.notification-container .btn-close {
  position: absolute;
  top: 8px;
  right: 8px;
  color: white;
  font-size: 20px;
  background: none;
  border: none;
  opacity: 1;
  cursor: pointer;
}

.notification-container .btn-close:hover {
  opacity: 0.8;
}

