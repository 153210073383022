//demo styles
table#example {
  padding: 0rem 0 2rem 0;
}
table.dataTable {
  font-size: 0.875rem;
}
#example2_wrapper {
  .dataTables_scrollBody {
    max-height: 33.25rem !important;
  }
}

#employees,
#custommers {
  padding: 0.5rem 0 1rem 0;
}
//////////
.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 0.125rem solid $border-color;
  border-top: 0;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  border-top: 0;
}

table.dataTable tbody tr,
table.dataTable tbody td {
  background: transparent !important;
}

table.dataTable thead th {
  color: $headings-color;
  white-space: nowrap;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
  padding: 1.25rem 0.9375rem;
  @at-root [data-theme-version="dark"] & {
    color: $white;
  }
  @include respond("laptop") {
    font-size: 1rem;
  }
}

table.dataTable tbody td {
  padding: 1.25rem 0.9375rem;
  font-size: 1rem;
  font-weight: 400;
  border-bottom: 0;
  @include respond("phone") {
    padding: 0.5rem 0.3125rem;
  }
  @include respond("laptop") {
    font-size: 0.875rem;
    padding: 0.5rem 0.9375rem;
  }
}

table.dataTable tr.selected {
  color: var(--primary);
}

table.dataTable tfoot th {
  color: $dark;
  font-weight: 600;
  @at-root [data-theme-version="dark"] & {
    color: $white;
  }
}
/* .dataTables_wrapper .dataTables_paginate .paginate_button{
	border-radius: 12px;
    padding: 12px 20px;
	background:$primary-light;
	color:$primary;
	&:focus,
	&:active,
	&:hover{
		color: $white !important;
		background: $primary;
		border: 0 !important;
		box-shadow: none;
	}
	&.current{
		color: $white !important;
		background: $primary;
		border: 0 !important;
	}
	&.current:hover,
	&.previous:hover,
	&.next:hover{
		background: $primary;
		color: $white !important;
	}
} */
td.text-ov {
  white-space: nowrap;
}

.dataTables_wrapper .dataTables_paginate {
  align-items: center;
  display: flex;
  flex-flow: wrap;
  .paginate_button.previous,
  .paginate_button.next {
    font-size: 1.125rem;
    height: 50px;
    width: 50px;
    background: transparent;
    border-radius: $radius;
    padding: 0 0.45rem;
    line-height: 3.125rem;
    margin: 0 0.625rem;
    display: inline-block;
    color: var(--primary) !important;
    &.current:hover,
    &.previous:hover,
    &.next:hover {
      color: $white !important;
      background: var(--primary) !important;
      border-color: transparent;
    }
    &.disabled {
      color: var(--primary) !important;
    }
    @include respond("phone") {
      height: 2.125rem;
      width: fit-content;
      line-height: 2.125rem;
      padding: 0 0.45rem;
    }
  }
  span {
    .paginate_button {
      height: 48px;
      width: 48px;
      padding: 0;
      margin-left: 0rem;
      margin: 0 0.125rem;
      line-height: 3.125rem;
      text-align: center;
      font-size: 1rem;
      border-radius: $radius;
      border: 0 !important;
      color: var(--primary) !important;
      background: var(--rgba-primary-1) !important;
      @include respond("phone") {
        height: 2.125rem;
        width: 2.125rem;
        line-height: 2.325rem;
      }
      &.current,
      &:hover {
        color: var(--primary) !important;
        &:hover {
          color: $white !important;
          background: var(--primary) !important;
        }
      }
    }
  }
}

.dataTables_wrapper {
  input[type="search"],
  input[type="text"],
  select {
    border: 0.0625rem solid #e2e2e2;
    padding: 0.3rem 0.5rem;
    color: rgba(113, 93, 93, 1);
    border-radius: 0.3125rem;
    @at-root [data-theme-version="dark"] & {
      background: $d-bg;
      border-color: $d-border;
      color: $white;
    }
  }
  .dataTables_length {
    margin-bottom: 0.9375rem;
    .bootstrap-select {
      width: 5rem !important;
      margin: 0 0.3125rem;
      border: 1px solid #eee;
      border-radius: 0.5rem;
    }
  }
}
table.dataTable.no-footer {
  border-bottom: 0;
}

.rounded-lg {
  min-width: 1.875rem;
}
.dataTables_scroll {
  padding: 1rem 0;

  &Foot {
    padding-top: 1rem;
  }
}

.dataTablesCard {
  background-color: $white;
  border-radius: $radius;
  &.border-no {
    td {
      border-top: 0 !important;
    }
  }
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
  @include media-breakpoint-down(md) {
    text-align: left;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: $body-color !important;
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: $body-color;
  border-radius: 1rem;
  padding: 0.125rem 0;
  margin-bottom: 1.25rem;
}
.paging_simple_numbers.dataTables_paginate {
  padding: 0.3125rem 0.3125rem;
  @include respond("phone") {
    padding: 0.8125rem 0.3125rem;
    justify-content: space-between;
  }
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: $body-color !important;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: $border-color;
  @at-root [data-theme-version="dark"] & {
    border-color: $d-border;
  }
}

.dataTables_wrapper {
  .dataTables_length {
    .bootstrap-select {
      .dropdown-toggle {
        font-size: 0.813rem !important;
        padding: 0.625rem 1rem;
      }
    }
  }
}

.view-button,
.download-button,
.action-button,
.email-button,
.resend-button {
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  border-radius: 8px;
  margin: 0px 1.5px;
}

.action-button {
  background-color: var(--bs-success);
  border: none;
}

.download-button {
  background-color: var(--bs-gray);
  border: none;
}

.action-button-two {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
}

.view-button {
  background-color: var(--bs-primary);
}

.email-button {
  background-color: var(--bs-info);
}

.resend-button {
  background-color: var(--bs-cyan);
}

.td-center {
  text-align: center;
  vertical-align: middle;
}

.max-width-column {
  min-width: 600px !important;
  max-width: 610px !important;
  white-space: normal !important;
}

.button-reset {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
}