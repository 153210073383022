.form-control.input-search{
	height:38px;
	border-radius:8px;
	margin-top:8px;
	margin-bottom:8px;
}
.input-search{
	height:38px;
	border-radius:8px;
	margin-top:8px;
	margin-bottom:8px;
	border:1px solid #eee;
	padding: 0 8px;
}


.filtering-table thead th:first-child{
	width:70px;
}
.table tfoot tr {
    border-bottom-width: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-color: #EEEEEE;
}

.filter-pagination{
	display: inline-block;
    background: #f0f0f0;
    border-radius: 10px;
    padding: 5px 5px;
}
.filter-pagination .previous-button,
.filter-pagination .next-button{
    font-size: 16px;
    border: 0 !important;
    width: auto;
	padding: 14px 20px;
	margin: 0 5px;
    border-radius: 10px;
    display: inline-block;
    background-color: #fff;
	color: #FC8019;
}
.filter-pagination button:hover{
	background:	#8fa3b5;
	color:#fff;
}
.filter-pagination button[disabled]{
	opacity:0.5;
	cursor: default;
}
.filter-pagination button[disabled]:hover{
	background: #8fa3b5;
	opacity:0.5;
	color:#fff;
}
.table-index input[type=number] {
    color: #969ba0;
    width: 70px;
    border: 0;
    background-color: #f8f8f8;
    padding: 6px 0 6px 10px;
}

.updated-span {
    background-color: #33A9FF;
    border-color: #33A9FF;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 10px;
}

.archived-span {
    background-color: #1E466A;
    border-color: #1E466A;
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 10px;
}

.table-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

.table-control {
    flex: 0 0 160px;
    max-width: 200px;
    min-width: 120px;
}


@media only screen and (max-width: 575px) {

    .filter-pagination .previous-button,
    .filter-pagination .next-button{
        padding:8px 10px;
        font-size:14px;
    }
}